@import '~antd/dist/antd.css';
.App {}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.logo {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

html,
body,
#root,
.App,
.dashboard-layout,
.ant-layout {
    min-height: 100%;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.dashboard-layout header {
    background-color: #fff;
    height: auto;
    padding-left: 0 !important;
}

.dashboard-layout a.logo {
    /* background: url("logo-text-flat.svg") center no-repeat; */
    background-size: contain;
    float: left;
    width: 230px;
    height: 54px;
    margin: 5px 0;
    font-size: 1.25rem;
    color: #000;
    font-weight: 400;
}

.dashboard-layout .ant-menu {
    border: none;
}

.dashboard-layout footer {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.dashboard-layout .dashboard-layout-content {
    /* margin-bottom: 70px; */
    padding: 4px 10px;
}

.dashboard-layout .ant-layout-content {
    overflow: auto;
}

.ant-layout,
body {
    background-color: rgba(247, 250, 252, 1) !important;
    background-image: url('../public/bg4.gif');
    background-repeat: repeat;
    background-position: center 110px;
    background-size: 15%;
    text-rendering: optimizeLegibility;
}

.dashboard-layout .ant-layout-sider {
    background-color: rgba(247, 250, 252, 1) !important;
}

.dashboard-layout div.ant-layout-sider-trigger {
    /* bottom: 70px !important; */
    background-color: #e6f7ff;
    color: #1890ff;
}

.dashboard-layout .ant-statistic-content {
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    font-size: large;
    padding-bottom: 10px;
}

.dashboard-layout .ant-card button {
    /* float: right; */
}

.professor-course-content .ant-tabs-tab {
    padding-left: 0 !important;
    padding-right: 5px !important;
}

.ant-card-hoverable.always {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
}

.courses-list .ant-list-item-meta-description {
    color: rgba(0, 0, 0, 0.85);
}

.site-page-header-responsive .ant-page-header-heading-title {
    white-space: initial !important;
}

.ant-tabs-left.ant-tabs-mobile>.ant-tabs-nav .ant-tabs-tab {
    padding-left: 0;
    padding-right: 0;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
    text-align: left;
}

.ant-ribbon-wrapper>.ant-layout-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
}

.header-col {
    background-color: #fafafa;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}